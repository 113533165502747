




























































import { Component, Vue, Watch } from 'vue-property-decorator';
import { shipArrivalStoreActions, shipArrivalStoreGetter, shipArrivalStoreMutations } from '@/store/shipArrival.store';
import ShipArrival from '@/models/ShipArrival.model';
import { namespace } from 'vuex-class';
import { calendarStoreGetter, calendarStoreMutations } from '@/store/calendar.store';
import Slot from '@/models/Slot.model';
import SlotBookingRequest from '@/interfaces/SlotBookingRequest.interface';
import Voyage from "@/models/Voyage.model";
import Vessel from "@/models/Vessel.model";

const ShipArrivalStore = namespace('shipArrival');
const CalendarStore = namespace('calendar');

enum SORT_TYPE {
  "SHIP_ASC" = "SHIP_ASC",
  "SHIP_DESC" = "SHIP_DESC",
  "ETA_ASC" = "ETA_ASC",
  "ETA_DESC" = "ETA_DESC"
}

@Component({
    components: {
        VoyageKeyDataComponent: () => import(
            /* webpackChunkName: "VoyageKeyDataComponent" */
            '@/components/calendar/misc/VoyageKeyData.component.vue')
    }
})
export default class ShipArrivalPickerComponent extends Vue {

    @CalendarStore.Getter(calendarStoreGetter.SELECTION_INFORMATION)
    private selectionInformation!: undefined | Slot | Partial<SlotBookingRequest>;

    @CalendarStore.Mutation(calendarStoreMutations.CLEAR_SELECTION)
    private clearSelection!: () => void;

    @CalendarStore.Mutation(calendarStoreMutations.SET_ITEM_FOCUSSED)
    private setItemFocussed!: (value: string | undefined) => void;

    @Watch('selectionInformation')
    private async onSelectionInformationChange() {
        if (this.selectionInformation instanceof Slot) {
            try {
                // Make it smooth
                this.calendarShipArrival = (this.selectionInformation as Slot).booking.shipArrival as ShipArrival;
                this.clearSelection();
                this.setItemFocussed(undefined);
                this.isLoading = true;
                setTimeout(async () => {
                    if (this.calendarShipArrival) { // can be null if ship arrival were resetted through double click for example
                        const freshShipArrival = await this.getShipArrival({ id: this.calendarShipArrival!.id! });
                        this.openShipArrivals = [freshShipArrival];
                        this.calendarShipArrival = freshShipArrival;
                    }
                    this.isLoading = false;
                }, 750);
            } catch (e) {
                this.$notifyErrorSimplified('CALENDAR.NOTIFICATIONS.SHIP_ARRIVALS_LOADING_ERROR');
            }
        }
    }

    @ShipArrivalStore.Action(shipArrivalStoreActions.GET_OPEN_SHIP_ARRIVALS)
    private getOpenShipArrivalsAction!: (payload?: { filter: string }) => Promise<ShipArrival[]>;

    @ShipArrivalStore.Action(shipArrivalStoreActions.GET)
    private getShipArrival!: (payload: { id: string }) => ShipArrival;

    @ShipArrivalStore.Mutation(shipArrivalStoreMutations.SET_CALENDAR_ARRIVAL)
    private setCalendarArrival!: (value: ShipArrival | null) => void;

    @ShipArrivalStore.Getter(shipArrivalStoreGetter.CALENDAR_SHIP_ARRIVAL)
    private _calendarShipArrival!: ShipArrival | null;

    public get calendarShipArrival(): ShipArrival | null {
        return this._calendarShipArrival;
    }

    public set calendarShipArrival(value: ShipArrival | null) {
        this.setCalendarArrival(value);
    }

    public openShipArrivals: ShipArrival[] = [];
    public isLoading: boolean = false;

    public SORT_TYPE = SORT_TYPE;
    private selectedSortType: SORT_TYPE = SORT_TYPE.SHIP_ASC;

    /**
     *  Handle update of ship arrivals
     */
    private async updateOpenShipArrivals(filterValue?: string) {
        // Do not update if a arrival was selected
        try {
            this.isLoading = true;
            this.openShipArrivals = await this.getOpenShipArrivalsAction(filterValue ? { filter: filterValue } : undefined);
        } catch (e) {
            this.$notifyErrorSimplified('CALENDAR.NOTIFICATIONS.SHIP_ARRIVALS_LOADING_ERROR');
        } finally {
            this.isLoading = false;
        }
    }

    public onChange() {
        if (!this.calendarShipArrival) {
            this.updateOpenShipArrivals();
        }
    }

    // Sort all the open ship arrivals by name (ASC or DESC) or by ETA (ASC / DESC)
    public sortShipArrivals() {
      switch (this.selectedSortType){
        case SORT_TYPE.SHIP_ASC:
          this.openShipArrivals = this.openShipArrivals.sort((a, b) => (((a as ShipArrival).voyage as Voyage).vessel as Vessel).name!.toLowerCase()
          .localeCompare((((b as ShipArrival).voyage as Voyage).vessel as Vessel).name!.toLowerCase()));
          break;
        case SORT_TYPE.SHIP_DESC:
          this.openShipArrivals = this.openShipArrivals.sort((a, b) => (((b as ShipArrival).voyage as Voyage).vessel as Vessel).name!.toLowerCase()
            .localeCompare((((a as ShipArrival).voyage as Voyage).vessel as Vessel).name!.toLowerCase()));
          break;
        case SORT_TYPE.ETA_ASC:
          this.openShipArrivals = this.openShipArrivals.sort((a, b) =>
            ((((a as ShipArrival).voyage as Voyage).eta as any) - ((((b as ShipArrival).voyage as Voyage).eta as any))));
          break;
        case SORT_TYPE.ETA_DESC:
          this.openShipArrivals = this.openShipArrivals.sort((a, b) =>
            ((((b as ShipArrival).voyage as Voyage).eta as any) - ((((a as ShipArrival).voyage as Voyage).eta as any))));
      }
    }
}
